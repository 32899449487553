import React from 'react'

import Footer from 'components/Footer'
import Meta from 'components/common/Meta'
import Navbar from 'components/Navbar'

interface LayoutProps {
  title?: string
  isFooterVisible?: boolean
  backgroundImage?: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({
  backgroundImage,
  isFooterVisible = true,
  title,
  children,
}) => {
  return (
    <>
      <Meta title={title} />
      {backgroundImage}
      <Navbar />
      {children}
      {isFooterVisible && <Footer />}
    </>
  )
}

export default Layout
