import React from 'react'
import { Link } from 'gatsby'

import Divider from 'components/common/Divider'

import TEXT from 'locales/home.json'

const Footer = () => (
  <footer className="flex flex-col items-start w-full px-4 mb-16 md:px-0 md:mb-0">
    <Divider className="pb-4" />
    <div className="container mx-auto">
      <div className="footer-links flex flex-col items-start justify-between w-full h-20 md:flex-row md:px-16 md:h-12">
        <div className="footer-links__company flex justify-between items-center w-60 md:w-56">
          <span className="text-gray-300">{TEXT.footer.copyright}</span>
        </div>
        <div className="footer-links__policy flex justify-between items-center gap-8">
          <Link className="body-text-2 text-primary" to="/imprint">
            {TEXT.footer.imprint}
          </Link>
          <Link className="body-text-2 text-primary" to="/policy">
            {TEXT.footer.privacy}
          </Link>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
