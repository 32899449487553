import React from 'react'

interface DividerProps {
  className: string
}

const Divider = ({ className }: DividerProps) => (
  <hr
    className={['w-full border-1 border-primary opacity-25', className].join(
      ' '
    )}
  />
)

export default Divider
