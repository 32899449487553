/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import CookieBanner from 'components/CookieBanner'
import Layout from 'components/Layout'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Link } from '@material-ui/core'

const PrivacyPolicyPage: React.FC = () => {
  return (
    <Layout title="Privacy Policy">
      <main className="max-w-3xl p-8 sm:p-12 grid grid-flow-row grid-cols-1 gap-3">
        <article className="policy-article">
          <h1>Privacy &amp; Cookie Policy</h1>
          <p>Last Updated Feb 2021</p>
          <h2>Privacy Policy</h2>
          <p>
            This privacy policy ("Privacy Policy") applies to the usage of the
            website phase2 ("Website") operated by phase2 GmbH,
            Fraumünsterstrasse 16, Zürich 8001, Switzerland ("phase2", "we",
            "our", or "us"). At phase2, we are committed to protecting your
            privacy and want you to be secure when visiting our Website.
          </p>
          <p>
            By accessing the Website, you ("User", "you", "your", or "yours")
            agree to the collection, usage, processing, and disclosure of
            information in accordance with this Privacy Policy.
          </p>
          <p>
            We attach great importance to the protection and security of your
            Personal Data. We regard the protection of your private sphere and
            the compliance with the data protection requirements as the key
            matter when collecting, processing, and using your Personal Data.
            Therefore, your Personal Data are collected, processed, and used
            exclusively in compliance with the provisions of data protection
            laws, particularly the Swiss Federal Act on Data Protection (FADP)
            and the General Data Protection Regulation (GDPR).
          </p>
          <h3>Definitions</h3>
          <p>
            "Personal Data" is any information relating to an identified or
            identifiable natural person; an identifiable natural person is one
            who can be identified, directly or indirectly, in particular by
            reference to an identifier such as a name, an identification number,
            location data, an online identifier or to one or more factors
            specific to the physical, physiological, genetic, mental, economic,
            cultural or social identity of that natural person.
          </p>
          <p>
            "Data Subject" is any identified or identifiable natural person,
            i.e., one who can be identified, directly or indirectly, in
            particular by reference to an identifier such as a name, an
            identification number, location data, an online identifier, or to
            one or more factors specific to the physical, physiological,
            genetic, mental, economic, cultural or social identity of that
            natural person.
          </p>
          <p>
            "Data Controller" means the natural or legal person who (either
            alone or jointly or in common with other persons) determines the
            purposes for which and the manner in which any personal information
            are, or are to be, processed.
          </p>
          <p>
            "Data Processor" means any natural or legal person who processes
            Personal Data on behalf of the Data Controller. We may use the
            services of various Service Providers in order to process your
            Personal Data more effectively.
          </p>
          <h3>Personal Data We Collect</h3>
          <p>
            We collect the following Personal Data about you when you use our
            Website, including taking certain actions within it.
          </p>
          <TableContainer
            component={props => (
              <Paper
                {...props}
                style={{ backgroundColor: '#1e3445' }}
                className="text-white! mb-6"
              />
            )}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Action</TableCell>
                  <TableCell align="right">Personal Data collected</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>When you browse our Website</TableCell>
                  <TableCell>
                    Website usage and interaction Technical data Statistical
                    data
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>When you log into our Website</TableCell>
                  <TableCell>
                    Contact details Login information Uploaded documents
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    When you sign up for our newsletter and other marketing
                    material
                  </TableCell>
                  <TableCell>Email address</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    When you communicate with us electronically
                  </TableCell>
                  <TableCell>Contact details</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>When you submit request</TableCell>
                  <TableCell>Contact details, request info</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <h3>Legal Basis and Purposes</h3>

          <p>We may collect Personal Data for the following purposes:</p>
          <ul>
            <li>
              Providing information about offers, services, websites, and other
              platforms where we have an online presence
            </li>
            <li>
              Communicating with third parties and processing their queries
              (e.g., applications, media queries);
            </li>
            <li>
              Examining and optimizing needs analysis procedures to directly
              address customers, as well as collecting personal data from
              publicly accessible sources for the purpose of customer
              acquisition;
            </li>
            <li>
              Advertising and providing information about our services and
              offers (including conducting events and sending the annual report)
              if you have not refused permission for the use of your data (if we
              send you advertising as a current customer, you may refuse
              permission at any time and we will place you on a list to block
              the sending of further advertising);
            </li>
            <li>Market surveys, opinion research, media monitoring;</li>
            <li>
              Asserting and defending legal claims in the context of legal
              disputes and official procedures;
            </li>
            <li>
              Preventing and investigating crimes and other misconduct (e.g.,
              conducting internal investigations, data analyses to combat
              fraud);
            </li>
            <li>
              Safeguarding our operations, in particular, our IT, our websites,
              and other platforms;
            </li>
            <li>
              IT, building, and system security measures to protect our
              employees and other persons, as well as assets that belong to us
              or, have been entrusted to us (e.g., access controls, visitor
              lists, network and mail scanners, telephone records);
            </li>
          </ul>
          <p>
            Our legal basis for collecting and using the Personal Data described
            in this Privacy Policy depends on the Personal Data we collect and
            the specific purposes for which we collect it.
          </p>
          <p>
            Contract: To perform our contractual obligations or take steps
            linked to a contract with you.
          </p>
          <ul>
            <li>To provide and protect our Website and services.</li>
            <li>
              To administer, manage and develop our business and services.
            </li>
          </ul>
          <p>
            Consent: We may rely on your freely given consent at the time you
            provided your Personal Data.
          </p>
          <ul>
            <li>
              To provide you with news, special offers, newsletters, and general
              information about services we offer (with your explicit consent).
            </li>
          </ul>
          <p>
            Legitimate interests: We may rely on legitimate interests based on
            our evaluation that the processing is fair and reasonable.
          </p>
          <ul>
            <li>To maintain and improve our Website and services.</li>
          </ul>
          <p>
            Public interest: To meet regulatory and public interest obligations.
          </p>
          <ul>
            <li>To comply with applicable regulation and legislation.</li>
          </ul>
          <h3>Data Retention</h3>
          <p>
            We retain your Personal Data only for as long as is necessary for
            the purposes set out in Section 3 of this Privacy Policy and to the
            extent necessary to comply with our legal obligations, resolve
            potential disputes and enforce our legal agreements and policies.
          </p>
          <h3>Service Providers and Data Transfers</h3>
          <p>
            We may employ third-party companies ("Service Providers") to
            facilitate our website's operation, help us analyze how our Website
            is used, or perform Website-related services, such as IT
            infrastructure services. These third parties have access to your
            Personal Data only and insofar as necessary to perform these tasks
            on our behalf. They are required to safeguard it in accordance with
            our contractual obligations and applicable data protection
            legislation.
          </p>
          <p>
            Such safeguards may include transfer to countries that have been
            deemed to provide an adequate level of protection, applying standard
            data protection model clauses, binding corporate rules, or other
            standard contractual obligations that provide for appropriate
            protection of data.
          </p>
          <p>
            We may also use recruiting websites such as LinkedIn and JobCloud to
            connect with job candidates. If you apply for a job using a
            third-party website, please observe that website's privacy policy
            prior to submitting your data.
          </p>
          <h3>Data Disclosure</h3>
          <p>
            We may disclose your Personal Data in the good faith belief that
            such action is necessary:
          </p>
          <ul>
            <li>
              To comply with a legal obligation (i.e., if required by law or in
              response to valid requests by public authorities, such as a court
              or government agency);
            </li>
            <li>
              To protect the security of the Website and defend our rights or
              property;
            </li>
            <li>
              To prevent or investigate possible wrongdoing in connection with
              our company;
            </li>
            <li>To protect ourselves against legal liability.</li>
          </ul>
          <h3>Data Security</h3>
          <p>
            We take reasonable technical and organizational security measures
            that we deem appropriate in order to protect your data against
            manipulation, loss, or unauthorized third-party access. Our security
            measures are continually adapted to technological developments.
          </p>
          <p>
            We also take internal data privacy very seriously. Our employees and
            the Service Providers that we retain must maintain secrecy and
            comply with applicable data protection legislation. They are also
            granted access to Personal Data only insofar as this is necessary
            for them to carry out their respective tasks or mandate.
          </p>
          <p>
            The security of your Personal Data is important to us but remember
            that no transmission method over the Internet or method of
            electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your Personal Data, we
            cannot guarantee its absolute security. We recommend using antivirus
            software, a firewall, and other similar software to protect your
            system.
          </p>
          <h3>Data Protection Rights</h3>
          <p>
            You have the below data protection rights on the basis of applicable
            data protection laws. Please note that we may ask you to verify your
            identity (e.g., with a copy of an ID card, if your identity cannot
            otherwise be clearly identified) before responding to such requests.
          </p>
          <ul>
            <li>
              <p>
                Right of access: You have a right to request a copy of your
                Personal Data, which we will provide to you in an electronic
                form.
              </p>
            </li>
            <li>
              <p>
                Right to amendment: You have the right to ask us to correct our
                records if you believe they contain incorrect or incomplete
                information about you.
              </p>
            </li>
            <li>
              <p>
                Right to withdraw consent: If you have provided your consent to
                the processing of your Personal Data, you have the right to
                withdraw your consent. This includes cases where you wish to
                opt-out of marketing communications. Once we have received
                notification that you have withdrawn your consent, we will no
                longer process your information for the purpose(s) to which you
                originally consented unless there is another legal basis for
                processing. Please click on the 'unsubscribe' link in the email
                you received to stop receiving emails from us.
              </p>
            </li>
            <li>
              <p>
                Right to erasure: You have the right to request that we delete
                your Personal Data when it is no longer necessary for the
                purposes for which it was collected or when it was unlawfully
                processed.
              </p>
            </li>
            <li>
              <p>
                Right to restriction of processing: You have the right to
                request the restriction of our processing of your Personal Data
                where you believe it to be inaccurate, our processing is
                unlawful, or where we no longer need to process it for the
                initial purpose but where we are not able to delete it due to a
                legal obligation or because you do not want us to delete it.
              </p>
            </li>
            <li>
              <p>
                Right to portability: You have the right to request that we
                transmit your Personal Data to another data controller in a
                common format such as Excel, where this is data which you have
                provided to us and where we are processing it on the legal basis
                of your consent or in order to perform our contractual
                obligations.
              </p>
            </li>
            <li>
              <p>
                Right to object to processing: Where the legal basis for our
                processing of your Personal Data is our legitimate interest, you
                have the right to object to such processing on grounds relating
                to your particular situation. We will abide by your request
                unless we have a compelling legal basis for the processing,
                which overrides your interests or needs to continue to process
                the Personal Data for the exercise or defense of a legal claim.
              </p>
            </li>
            <li>
              <p>
                Right to lodge a complaint with a supervisory authority: You
                have the right of appeal to a data protection supervisory
                authority if you believe that the processing of your Personal
                Data violates data protection law. The competent data protection
                authority in Switzerland is the Federal Data Protection and
                Information Commissioner
              </p>
            </li>
          </ul>
          <h3>Links to Third-Party Apps and Sites</h3>
          <p>
            Our Website may contain links to websites or apps that are not
            operated by us. If you click on a third-party link, you will be
            directed to that third party’s site or app.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies, or practices of any third-party sites or
            services.
          </p>
          <h3>Cookies</h3>
          <p>
            Cookies are files with a small amount of data that is commonly used
            as an anonymous unique identifier. These are sent to your browser
            from the website you visit and stored on your computer's hard drive.
          </p>
          <p>
            Please refer to our Cookie Policy for a full list of the cookies we
            use, their function, and your choices.
          </p>
          <h3>Changes to This Privacy Policy</h3>
          <p>
            We may update our Privacy Policy from time to time. We, therefore,
            encourage you to review this page periodically for any changes.
          </p>
          <p>
            Changes to this Privacy Policy are effective when they are posted on
            this page.
          </p>
          <h3>Contact Us</h3>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at:
          </p>
          <p>
            phase2 GmbH <br />
            Fraumünsterstrasse 16
            <br />
            Zürich 8001
          </p>

          <p>
            <Link href="mailto:scale@ph2.io">scale@ph2.io</Link>
          </p>
          <h2>Cookie Policy</h2>
          <p>
            At https://ph2.io/ ("We", "us", "our"), we strive to honor the
            privacy and security of any data we collect from visitors using our
            website whilst we act as a personal data Controller under relevant
            data protection rules and regulations. Our website uses cookies, in
            combination with pixels, local storage objects, and similar devices
            (collectively, “cookies” unless otherwise noted) to distinguish you
            from other users of the website. This helps us provide you with a
            good experience, improve our service, and personalize ads and
            content while using the website.
          </p>
          <p>
            This cookie policy (“Cookie Policy”) describes the types of cookies
            we use on the website and our purposes for using them.
          </p>
          <p>
            If you have questions about this Cookie Notice, please contact us at{' '}
            <Link href="mailto:scale@ph2.io">scale@ph2.io</Link>. For a complete
            description and list of the cookies that we currently use on the
            website, please review our Cookie List found in section 5 of this
            document. For more information about our privacy practices, please
            review our privacy policy:{' '}
            <Link href="/privacy#privacy-policy">https://ph2.io/policy</Link>.
            If you have any questions or comments regarding this notice or data
            privacy, please contact us at{' '}
            <Link href="mailto:scale@ph2.io">scale@ph2.io</Link>.
          </p>
          <p>
            Please read this Cookie Notice carefully for details about why we
            use cookies and the information they collect from and about you.
          </p>
          <h3>Cookie Definitions</h3>
          <p>
            A cookie is a small file of letters and numbers that we store on
            your browser or your device's hard drive, which is like computer
            memory.
          </p>
          <p>
            First and third-party cookies: whether a cookie is 'first' or
            'third' party refers to the domain placing the cookie.
          </p>
          <ul>
            <li>
              First-party cookies are those set by a website that is being
              visited by the user at the time (e.g., cookies placed by our
              website domain).
            </li>
            <li>
              Third-party cookies are cookies set by a domain other than that of
              the website being visited by the user. If a user visits a website
              and another entity sets a cookie through that website, this would
              be a third-party cookie.
            </li>
          </ul>
          <p>
            Persistent cookies: these cookies remain on a user’s device for the
            period of time specified in the cookie. They have activated each
            time that the user visits the website that created that particular
            cookie.
          </p>
          <p>
            Session cookies: these cookies allow website operators to link a
            user's actions during a browser session. A browser session starts
            when a user opens the browser window and finishes when they close
            the browser window. Session cookies are created temporarily. Once
            you close the browser, all session cookies are deleted.
          </p>
          <h3>What Cookies Do We Use and Why?</h3>
          <p>
            Generally, the website uses cookies to distinguish you from other
            users of the website. This helps us to provide you with a good
            experience when you browse the website and also allows us to improve
            it. The cookies we may use on the website may be categorized as
            follows:
          </p>
          <ul>
            <li>Strictly necessary</li>
            <li>Performance</li>
            <li>Functionality</li>
            <li>Targeting</li>
          </ul>
          <p>Some cookies may fulfill more than one of these purposes.</p>
          <p>
            'Strictly Necessary' cookies let you move around the website and use
            essential features like secure areas. Without these cookies, we
            cannot provide the requested services.
          </p>
          <p>We use these Strictly Necessary cookies to:</p>
          <ul>
            <li>
              Identify you as being logged in to the website, and to
              authenticate you
            </li>
            <li>
              Make sure you connect to the right service on the website when we
              make any changes to the way it works
            </li>
            <li>For security purposes</li>
            <li>
              Make sure you connect to the right service on the website when we
              make any changes to the way it works
            </li>
          </ul>
          <p>
            If you prevent these cookies, we can't guarantee how the website or
            the website's security will perform during your visit.
          </p>
          <p>
            'Performance' cookies collect information about how you use the
            website, e.g., which pages you visit, and if you experience any
            errors. These cookies do not collect any information that could
            identify you and are only used to help us improve how the website
            works, understand our users' interests, and measure the
            effectiveness of our advertising.
          </p>
          <p>We use performance cookies to:</p>
          <ul>
            <li>
              Carry out web analytics: Provide statistics on how the website is
              used
            </li>
            <li>
              Perform affiliate tracking: Provide feedback to affiliated
              entities that one of our visitors also visited their site
            </li>
            <li>
              Obtain data on the number of users of the website that have viewed
              a product or service
            </li>
            <li>
              Help us improve the website by measuring any errors that occur
            </li>
            <li>Test different designs for the website</li>
          </ul>
          <p>
            Some of our performance cookies are managed for us by third parties.
          </p>
          <p>
            'Functionality' cookies are used to provide services or to remember
            settings to improve your visit.
          </p>
          <p>We use 'Functionality' cookies for such purposes as:</p>
          <ul>
            <li>
              Remember settings you've applied such as layout, text size,
              preferences, and colors
            </li>
            <li>
              Remember if we've already asked you if you want to fill in a
              survey
            </li>
            <li>
              Remember if you have engaged with a specific component or list on
              the website so that it won’t repeat
            </li>
            <li>Show you when you're logged in to the website</li>
            <li>To provide and show embedded video content</li>
          </ul>
          <p>Some of these cookies are managed for us by third parties.</p>
          <p>
            ‘Targeting’ cookies are used to track your visit to the website, as
            well other websites, apps, and online services, including the pages
            you have visited and the links you have followed, which allows us to
            display targeted ads to you on the website.
          </p>
          <p>We may use targeting cookies to:</p>
          <ul>
            <li>Display targeted ads within the website.</li>
            <li>
              To improve how we deliver personalized ads and content and measure
              ad campaigns' success on the website.
            </li>
          </ul>
          <h3>When do we require your consent</h3>
          <p>All cookies require your consent.</p>
          <p>
            We request your consent before placing them on your device. You can
            give your consent by clicking on the appropriate button on the
            banner displayed to you. If you do not wish to give consent or wish
            to withdraw your consent to any cookies at any time, you will need
            to delete and block or disable cookies via your browser settings;
            see below for more information on how to do this. Please note that
            disabling these cookies will affect the website's functionality and
            may prevent you from accessing certain features on the website.
          </p>
          <h3>How to Delete and Block our Cookies</h3>
          <p>
            Most web browsers allow some control of most cookies through the
            browser settings. However, if you use your browser settings to block
            all cookies (including strictly necessary Cookies), you may not be
            able to access all or parts of the website. Unless you have adjusted
            your browser setting to refuse cookies, our system will issue
            cookies as soon as you visit the website.
          </p>
          <h3>Changing your Cookie Settings.</h3>
          <p>
            The browser settings for changing your cookies settings are usually
            found in the 'options' or 'preferences' menu of your internet
            browser. In order to understand these settings, the following links
            may be helpful. Otherwise, it would help if you used the 'Help'
            option in your internet browser for more details.
          </p>
          <ul>
            <li>Cookie settings in Internet Explorer</li>
            <li>Cookie settings in Firefox</li>
            <li>Cookie settings in Chrome</li>
            <li>Cookie settings in Safari</li>
          </ul>
          <p>
            More information To find out more about cookies, including how to
            see what cookies have been set and how to manage and delete them,
            visit <Link href="www.aboutcookies.org">www.aboutcookies.org</Link>.
          </p>
          <p>You can use cookie settings below to disable specific cookies:</p>
          <CookieBanner />
          <h3>Contact us</h3>
          <p>
            For any questions, feel free to contact us at{' '}
            <Link href="mailto:scale@ph2.io">scale@ph2.io</Link>
          </p>
        </article>
      </main>
    </Layout>
  )
}

export default PrivacyPolicyPage
