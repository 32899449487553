import React from 'react'

interface CompanyLogoProps {
  className?: string
}

const CompanyLogo = ({ className }: CompanyLogoProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="32"
      fill="none"
      viewBox="0 0 160 43"
      className={className}
    >
      <mask
        id="mask0"
        width="22"
        height="29"
        x="0"
        y="14"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M.001 14.418H21.64v28.107H0V14.418z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M11.18 18.503c-2.964 0-5.074 1.84-6.69 4.132v3.86c1.616 2.291 3.726 4.132 6.69 4.132 3.546 0 5.747-2.65 5.747-6.061 0-3.413-2.2-6.063-5.747-6.063zm-6.6 24.023H0v-27.48H4.58v1.752h1.077c1.122-1.213 3.412-2.38 6.15-2.38 5.748 0 9.833 4.49 9.833 10.148 0 5.656-4.085 10.146-9.832 10.146-2.739 0-5.029-1.167-6.151-2.38H4.58v10.194z"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M31.851 34.084h-4.58V6.56h4.58V17.2h1.077c1.482-1.66 3.639-2.782 6.151-2.782 4.49 0 7.722 2.739 7.722 7.812v11.854h-4.579V23.218c0-2.65-.853-4.624-3.816-4.624-2.693 0-4.803 2.423-6.555 5.118v10.372zM67.424 25.867l-1.032-.628-6.958 1.705c-1.213.315-2.38.988-2.38 2.2 0 1.258.853 1.797 2.244 1.797 2.648 0 5.612-1.796 8.126-3.772v-1.302zm-3.726-11.45c5.702 0 8.081 3.054 8.081 6.96v7.768c0 .852.18 1.436 1.079 1.436.628 0 1.166-.448 2.512-1.48l2.021 2.962c-2.155 1.93-3.456 2.649-5.476 2.649a4.512 4.512 0 01-4.176-2.828h-1.392c-2.066 1.392-4.625 2.828-7.677 2.828-3.861 0-6.375-1.796-6.375-5.163 0-2.873 2.11-4.893 6.24-5.927l8.89-2.245v-.18c0-1.66-.988-2.738-4.221-2.738-3.007 0-5.925 1.347-8.305 3.502l-2.2-3.682c3.053-2.694 7.004-3.861 10.999-3.861zM88.402 26.136c-3.322-.583-6.24-1.93-6.24-5.613 0-3.95 4.084-6.105 9.024-6.105 3.636 0 6.913.988 9.472 2.65l-2.065 3.95c-2.468-1.661-4.488-2.515-7.676-2.515-2.335 0-4.31.494-4.31 1.752 0 1.122 1.032 1.437 2.648 1.706l7.183 1.257c3.008.539 5.209 2.2 5.209 5.343 0 3.996-4.58 6.15-9.473 6.15-3.637 0-7.812-.942-11-2.872l2.199-4.176c3.098 2.2 5.477 3.054 8.935 3.054 2.425 0 4.894-.54 4.894-1.841 0-1.078-.809-1.393-2.47-1.662l-6.33-1.078zM122.885 22.59l.403-.943c-.943-1.752-3.007-3.144-5.611-3.144-2.559 0-4.984 1.213-6.016 3.504l.807.583h10.417zm-4.671 12.122c-6.778 0-11.402-4.4-11.402-10.147s4.624-10.147 10.864-10.147c7.588 0 11.046 5.971 11.046 11.719h-16.254l-.897.628c.853 2.066 2.963 3.817 6.643 3.817 2.74 0 5.119-.898 7.634-2.784l2.245 3.054c-3.323 2.649-6.6 3.86-9.879 3.86zM137.956 6.725C140.449 2.985 144.547 0 149.312 0c5.656 0 9.173 3.296 9.173 8.06 0 8.642-13.893 9.265-15.407 14.03h16.119v3.92h-20.974v-1.782c0-10.243 15.32-9.887 15.32-16.033 0-2.495-1.96-3.876-4.231-3.876-3.072 0-5.744 2.183-7.838 5.078l-3.518-2.672z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default CompanyLogo
