import React from 'react'
import { Link } from 'gatsby'

import CompanyLogo from 'components/common/CompanyLogo'

import TEXT from 'locales/common.json'

interface NavLinkProps {
  href: string
}

const NavLink: React.FC<NavLinkProps> = ({ href, children }) => (
  <Link to={href} className="hover:text-primary">
    <h4>{children}</h4>
  </Link>
)

const Navbar = () => (
  <nav className="relative flex justify-center items-center md:justify-between w-full py-4 md:container md:mx-auto md:pl-16 md:pr-16">
    <Link to="/">
      <CompanyLogo />
    </Link>
    <div className="hidden md:grid grid-flow-col grid-rows-1 items-center gap-8">
      <NavLink href="/#section-clients">{TEXT.nav.navLink.clients}</NavLink>
      <NavLink href="/#section-testimonials">
        {TEXT.nav.navLink.testimonials}
      </NavLink>
      <NavLink href="/#section-team">{TEXT.nav.navLink.team}</NavLink>
      <NavLink href="/#section-about">{TEXT.nav.navLink.about}</NavLink>
      <NavLink href="https://app.ph2.io">{TEXT.nav.navLink.login}</NavLink>
    </div>
  </nav>
)

export default Navbar
